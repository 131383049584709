import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"

import { useMediaQuery } from 'react-responsive'
import instance from "../../components/api/httpclient";
import {navigate, useIntl, Link, FormattedMessage} from "gatsby-plugin-intl";
import moment from "moment";
import ENV from "../../../env";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
};

class BodyClass extends React.Component {

  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const defaultBookingUrl = {
  'fr-BE': "https://rendez-vous-atelier.nissan.be",
  'nl-BE': 'https://werkplaatsafspraak.nissan.be',
  'fr-LU': "https://rendez-vous-atelier.nissan.lu",
  'nl-NL': 'https://onderhoud.nissan.nl'
}

const OfferSingle = ({location}) => {
  const [offer, setOffer] = useState({});
  const [image, setImage] = useState({});
  const [dealer, setDealer] = useState({});
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : "";
  const currentDealer = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentDealer')) : "";
  const intl = useIntl();

  useEffect(() => {
    if (location) {
      if (location.state) {
        instance.get(`/dealers/` + currentDealer.id,
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            }
          })
          .then(function (response) {
            setDealer(response.data);
          });

        instance.get("/offers/" + location.state.id,
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            }
          })
          .then((response) => {
            setOffer(response.data);
            console.log(response.data);
            instance.get(response.data.thumbnail,
              {
                headers: {
                  'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                }
              })
              .then(function (res) {
                console.log(res.data);
                  if (undefined !== res.data.contentUrl) {
                    setImage(ENV.API_URL + res.data.contentUrl)
                  }
                }
              );
          }).catch((error) => {
          console.log('error', error);
        });
      }
    }
  },[]);


  const pageContent = () => {
    return(
      <div className="col-lg-8">
        <Link to="/offers/offers-list" className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span>{intl.formatMessage({ id: "offers.single.back" })}</Link>

        <div className="white-container">
          <div className="reward-single__img-wrapper mb-4" style={{backgroundImage: "url(" + image + ")"}}>
          </div>
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{offer.detailTitle}</h1>
          <div className="text mb-2"><strong>{intl.formatMessage({ id: "offers.single.description" })}</strong></div>
          <p className="mb-4" dangerouslySetInnerHTML={{__html: offer.detailDescription}}></p>

          <h3 className="text-uppercase"><span className="dark-red icon-ticket mr-2 "></span>{intl.formatMessage({ id: "offers.single.the_offer" })}</h3>

          <p className="mb-4" dangerouslySetInnerHTML={{__html: offer.offerDescription}}></p>

          <div className="text mb-4"><strong>{intl.formatMessage({id: "offers.single.date_due"})} {moment(offer.endDate, moment.ISO_8601).format('DD/MM/YYYY')}</strong></div>

          {/*<div className="mb-4">
            <div className="about-offer-bloc">
              <div className="d-flex get-quote mb-3">
                <a href="#" className="d-flex w-100 pb-3"><span className="icon icon-calcul-contract align-self-center"></span> <span className="text"><strong className="text-uppercase dark-red">Get a quote</strong><br />And book appointment</span></a>
              </div>
              <div className="d-flex links-contact">
                <a href="#"><span className="icon icon-pin-marker"></span> <span>Visit my<br />dealer</span></a>
                <a href="#"><span className="icon icon-phone"></span> <span>Call my<br />dealer</span></a>
              </div>
            </div>
          </div>*/}

          <div>
            <div className="about-offer-bloc">
              <div className="d-flex links-contact-go">

                {dealer.bookingUrl !== undefined &&
                <a href={encodeURI(dealer.bookingUrl)} target="_blank"><span className="icon icon-calendar"></span> <FormattedMessage id="offers.single.button_visit_dealer" values={{ lb:  (...chunks) => <span><br/>{chunks}</span> }}>{(...chunks) => <span>{chunks}</span>}</FormattedMessage></a>
                }
                {dealer.bookingUrl === undefined &&
                  <a href={encodeURI(defaultBookingUrl[currentUser.language])} target="_blank"><span className="icon icon-calendar"></span> <FormattedMessage id="offers.single.button_visit_dealer" values={{ lb:  (...chunks) => <span><br/>{chunks}</span> }}>{(...chunks) => <span>{chunks}</span>}</FormattedMessage></a>
                }
                {/* false &&
                (
                  <a href={encodeURI("https://www.google.com/maps/search/"+currentDealer.name+' '+currentDealer.address+' '+currentDealer.postcode+' '+currentDealer.city)} target="_blank"><span className="icon icon-pin-marker"></span> <FormattedMessage id="offers.single.button_visit_dealer" values={{ lb:  (...chunks) => <span><br/>{chunks}</span> }}>{(...chunks) => <span>{chunks}</span>}</FormattedMessage></a>
                )
                */}
              </div>
              <div className="d-flex links-contact-call">
                {currentDealer.phone &&
                (
                  <a href={encodeURI("tel:"+currentDealer.phone)}><span className="icon icon-phone"></span> <FormattedMessage id="offers.single.button_call_dealer" values={{ lb:  (...chunks) => <span><br/>{chunks}</span> }}>{(...chunks) => <span>{chunks}</span>}</FormattedMessage></a>
                )
                }
              </div>
            </div>
          </div>


        </div>
      </div>
    )
  }

  return(
    <Layout>
      <SEO title="Offers" />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="offers" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
}

export default OfferSingle
